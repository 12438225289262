.homescreen {
  overflow: hidden;
  position: relative;
  font-size: 1em;
  background: linear-gradient(-18deg, #252627 60%, #525358 100%);
  height: 100vh;
  min-height: 780px;
}

.homescreen::before {
  content: "";
  display: block;
  position: absolute;
  top: 25%;
  left: -10%;
  background: linear-gradient(-18deg, #252627 6%, #525358 100%);
  transform: skewY(-18deg) scaleY(1) translateY(14%);
  width: 120%;
  height: 65%;
  opacity: 1;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.logo {
  position: absolute;
  top: 58px;
  left: 50%;
  z-index: 2;
  width: 250px;
  margin: 0 -125px;
  text-align: center;
}

@media (min-width: 1000px) {
  .logo {
    top: 100px
  }
}

.logo h1 {
  width: 250px;
  margin: 1em auto;

  font-size: 1em;
  line-height: 1.2em;
  letter-spacing: 5px;
  animation: fade 2s 0s ease-out 1;
}

.logo h1 span {
  display: block;
  font-size: .85em;
  letter-spacing: .25em;
  color: rgba(255, 255, 255, .7);
}

.logo svg {
  width: 52px;
  margin: 0 25px;
  animation: zoom 2s ease-out 1;
}

.singpass {
  text-align: center;
  font-size: .85em;
  margin: .5em;
  opacity: .6;
}

.singpass a {
  text-decoration: none;
  color: #fff;
}

.singpass img {
  vertical-align: -23%;
}

.maininfo {
  z-index: 2;
  position: absolute;
  top: 48%;
  left: 47%;
  text-align: left;
  width: 440px;
  height: 200px;
  margin: -130px 80px;
}

.maininfo h3 {
  margin: 0 0 .2em;
  font-size: 3.4em;
  line-height: 1.05em;
  max-width: 7.8em;
}

.maininfo > p {
  font-size: 1.1em;
  margin: 0 0 1.4em;
  color: rgba(255, 255, 255, .6);
}

.promo {
  width: 260px;
  padding: 1.5em 3em;
  border-radius: .3em;
  margin: 0 0 0 5em;
}

.promo button {
  margin: 0 -1.5em;
  width: calc(250px + 3em);
  height: 44px;
}

.subtext {
  display: flex;
  font-size: 0.85em;
  width: 285px;
  color: rgba(255, 255, 255, .6);
  margin: 1.2em 0 0;
  position: relative;
}

.subtext b {
  color: #fff;
  font-size: 1em;
  line-height: 1.6em;
}

.subtext > img {
  width: 80px;
  object-fit: contain;
  float: left;
  position: relative;
  margin: .2em .75em .2em 0;
}

.termsnote {
  display: inline-block;
  margin: .5em 0 0 0;
  color: rgba(255, 255, 255, .4);
}

.termsnote a {
  color: rgba(255, 255, 255, .4);
}

.partnerlogos {
  position: absolute;
  bottom: 6%;
  left: 50%;
  width: 300px;
  margin: 0 -150px;
  display: flex;
  justify-content: space-around;
}

.partnerlogos > span {
  position: absolute;
  width: 100%;
  top: -1.5em;
  text-align: center;
  font-size: 0.875em;
  color: rgba(255, 255, 255, .6);
}

.bubble {
  position: absolute;
  background-color: #252627;
  background-image: linear-gradient(-18deg, #252627 0%, #525358 160%);
  z-index: 2;
  left: calc(35% - 260px);
  top: calc(55% + 10px);
  width: 15em;
  height: 15em;
  border-radius: 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: .85em;
  line-height: 1.2em;
  color: #ffffffaa;
  box-shadow: 0 0 0 2px #bca36c, .5em 1.5em 3em rgba(0,0,0,.2);
}

.bubble > span {
  max-width: 70%;
}

.bubble .big {
  color: #bca36c;
  text-shadow: 1px 1px 3px rgba(0,0,0,.4);
  margin-bottom: -.1em;
  font-family: AltoPro, sans-serif;
  font-size: 7.5em;
  line-height: 1em;
  font-weight: bolder;
}

.bubble .big:after {
  content: "%";
  line-height: 1em;
  font-size: .5em;
}


.benefits {
  background: #fff;
  color: #000;
}

.benefits > div {
  margin: 0 auto;
  max-width: calc(980px);
  box-sizing: border-box;
  padding: 1em 5em 3em;
  box-shadow: 0 50px 100px rgba(0,0,0,.1);
  background: #fff;
  position: relative;
  z-index: 2;
  border-radius: .5em .5em 0 0;
}

.benefits > div > button {
  margin: 2em auto;
  display: block;
}

.explorer {
  background: #f4f4f4;
  color: #222;
}

.explorer > div {
  position: relative;
  z-index: 2;
  max-width: calc(960px + 1em);
  justify-content: center;
  align-items: center;
  padding: 1em 2em 2em;
  width: 100%;
  box-sizing: border-box;
  margin: -1.5em auto 0;
  box-shadow: 0 -80px 100px rgba(0,0,0,0.1);
  background: #f4f4f4;
  border-radius: .5em .5em 0 0;
}


.benefits h3,
.performance h3 {
  font-weight: 200;
  text-align: center;
  font-size: 2.5em;
  margin: 1.5em 0 1.5em;
}

.benefits h3 + p,
.performance h3 + p {
  margin-top: -.5em;
  text-align: center;
}

.benefits h3:after,
.performance h3 + p:after {
  content: "";
  display: block;
  margin: 25px auto;
  border-top: 2px solid #e3e3e3;
  width: 180px;
}

.benefits .split {
  margin: 2em -1em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}


@media (max-width: 920px) {
  .benefits h3,
  .performance h3 {
    font-size: 1.8em;
  }
  .explorer > div {
    padding: 1em 2em -1em;
    margin: 0 auto;
  }
  .benefits > div {
    padding: 1em 2em 2em;
  }
  .benefits .split {
    margin: 1.5em -1em 0;
  }
}

.benefits ul {
  margin: 0;
  padding: 0 1em;
  max-width: 360px;
  min-width: 220px;
  flex: 1 1;
}

.benefits li {
  list-style-type: none;
  padding-bottom: .5em;
}

.benefits li > div {
  display: flex;
}
.benefits li h4 {
  margin: .8em 0 1em .8em;
  font-size: 1.2em;
  flex: 1;
}
.benefits li p {
  margin: 0 0 1em calc(48px + 1em);
  line-height: 1.5em;
}



.video {
  background: #f4f4f4;
  padding: 5em 1em 3em;
  text-align: center;
  color: #000;
}

.video button {
  margin-bottom: .8em;
}

.video > div {
  max-width: 720px;
  min-height: 175px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1em;
  border-radius: 5px;
  width: calc(100% - 2em);
  margin: 1em auto;
  background: #fff;
  box-shadow: 0 10px 30px rgba(0,0,0,0.15);
}

.video video {
  max-width: 720px;
  width: 100%;
}

.video .videofallback {
  max-width: 300px;
}




.phone {
  position: absolute;
  z-index: 1;
  left: calc(35% - 8px);
  top: calc(55% - 2px);
  transform: rotate(-24deg) skewX(24deg) skewY(6deg) scale(.6);
}

@media screen and (max-width: 920px) {
  .maininfo {
    text-align: center;
    width: 340px;
    margin: 0 -170px;
    left: 50%;
  }
  .maininfo h3 {
    font-size: 1.7em;
    max-width: unset;
  }
  .maininfo > p {
    font-size: 1em;
    margin: 0 0 .5em;
  }
  .bubble {
    top: calc(38% - 155px);
    left: 53%;
    transform: scale(.6);
  }
  .promo {
    text-align: left;
    margin: 0;
    padding: 1.5em 44px;
  }
  .partnerlogos {
    bottom: 10px;
  }
  
  .phone {
    top: calc(38% - 2px);
    left: calc(54% - 35px);
    transform: rotate(-20deg) skewX(22deg) scale(0.3);
  }
  .homescreen::before {
    transform: skewY(-20deg) scaleY(1) translateY(8vh);
  }
}

.phone-back {
  position: absolute;
  top: -3px;
  transform: translate(-50%, -50%);
  height: 734px;
  width: 430px;
  background-image: linear-gradient(to bottom, #3a3b3f 70%, #1d1d1f);
  border-radius: 74px 40px 84px 50px;
  box-shadow: -26px 8px 8px -10px rgba(0,0,0,0.3), -6px 6px 6px 0 rgba(0,0,0,0.6), -30px 14px 10px -10px rgba(0,0,0,0.5), -20px 40px 8px 0 rgba(0,0,0,0.1), -20px 30px 8px 0 rgba(0,0,0,0.1), inset 20px 0 15px -6px rgba(0,0,0,0.6), inset 2px 0 4px -2px rgba(0,0,0,0.6), inset 2px 0 1px -2px rgba(255,255,255,0.1), inset 0 -15px 10px -10px rgba(255,255,255,0.15);
}
.phone-back:before {
  content: "";
  display: block;
  height: 300px;
  width: 100px;
  position: absolute;
  background-color: #fff;
  left: 16px;
  bottom: 40px;
  border-radius: 20px;
  filter: blur(5px);
  opacity: 0.1;
  transform: rotate(4deg);
}
.phone-back:after {
  content: "";
  display: block;
  height: 300px;
  width: 100px;
  position: absolute;
  background-color: #fff;
  left: 26px;
  bottom: 40px;
  border-radius: 20px;
  filter: blur(4px);
  opacity: 0.25;
  transform: rotate(2deg);
}
.phone-screen {
  position: absolute;
  top: -16px;
  left: 14px;
  transform: translate(-50%, -50%);
  height: 710px;
  width: 400px;
  background-color: #000;
  border-radius: 48px 40px 52px 45px;
  box-shadow: -2px 0 0px -1px rgba(255,255,255,0.4), -1px 3px 3px -3px rgba(255,255,255,0.7);
}
.phone-display {
  position: absolute;
  z-index: 1;
  top: -23px;
  left: 20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 690px;
  width: 378px;
  background-color: #fbfbfb;
  border-radius: 45px 37px 41px 41px;
}
.phone-notch {
  position: absolute;
  top: -1px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  width: 210px;
  background-color: #131313;
  border-top: 6px solid #000;
  height: 21px;
  z-index: 3;
  border-radius: 0 0 20px 20px;
}
.phone-notch:after {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  background-color: transparent;
  position: absolute;
  left: -12px;
  top: -5px;
  border-radius: 50%;
  box-shadow: 5px -5px 0 -3px #000;
}
.phone-notch:before {
  content: "";
  display: block;
  height: 12px;
  width: 12px;
  background-color: transparent;
  position: absolute;
  right: -12px;
  top: -5px;
  border-radius: 50%;
  box-shadow: -4px -4px 0 -3px #000;
}
.phone-speaker {
  position: absolute;
  top: 6px;
  width: 58px;
  height: 6px;
  border-radius: 20px;
  background-color: #2b2b2b;
  overflow: hidden;
  box-shadow: 0 0 0 1px #363637, inset -3px 3px 1px 0px #000, inset -3px 4px 0px -1px rgba(255,255,255,0.5);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.phone-speaker:after {
  content: "";
  display: block;
  height: 4px;
  width: 4px;
  background-color: #87888c;
  position: absolute;
  right: -1px;
  border-radius: 50%;
  top: -1px;
}
.phone-speaker:before {
  content: "";
  display: block;
  height: 4px;
  width: 4px;
  background-color: #898a8e;
  position: absolute;
  right: -2px;
  border-radius: 50%;
  top: 4px;
}
.phone-left-side {
  position: absolute;
  left: 0;
  top: 60px;
  width: 30px;
  height: 100%;
}
.phone-left-side .phone-antena {
  position: absolute;
  height: 20px;
  width: 50px;
  left: -5px;
  top: 10px;
  border-radius: 50%;
  -webkit-transform: rotate(-40deg) skewX(20deg);
          transform: rotate(-40deg) skewX(20deg);
  background-color: transparent;
  box-shadow: 0px -2px 1px -2px #000;
}
.phone-left-side .phone-antena.bottom {
  top: auto;
  bottom: 128px;
}
.phone-left-side .phone-antena:after {
  content: "";
  display: block;
  height: 20px;
  width: 50px;
  position: absolute;
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
  left: -5px;
  top: 3px;
  border-radius: 50%;
  background-color: transparent;
  box-shadow: 0px -2px 1px -2px #000;
}
.phone-button {
  position: absolute;
  top: 100px;
  left: 4px;
  height: 47px;
  width: 12px;
  background-color: #010101;
  border-radius: 30px;
  -webkit-transform: skewY(-40deg);
          transform: skewY(-40deg);
  box-shadow: -32px -16px 10px 0 rgba(0,0,0,0.4), -4px 0 4px 0 rgba(0,0,0,0.4);
}
.phone-button.bottom {
  top: 160px;
}
.phone-button.top {
  top: 48px;
  height: 29px;
  width: 14px;
  left: 2px;
  border-radius: 10px 10px 10px 3px;
  box-shadow: inset 2px 0 0 -1px rgba(255,255,255,0.25);
}
.phone-button.top:after {
  height: 27px;
  width: 8px;
  left: 4px;
  top: 0px;
}
.phone-button.top:before {
  height: 28px;
  width: 8px;
  left: 6px;
  top: 0px;
  box-shadow: 1px 0 0 0 rgba(0,0,0,0.4);
}
.phone-button:before {
  content: "";
  display: block;
  position: absolute;
  height: 45px;
  width: 10px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(85%, #6a6b6f), to(#0b0b0b));
  background-image: linear-gradient(to bottom, #6a6b6f 85%, #0b0b0b);
  border-radius: 20px;
}
.phone-button:after {
  content: "";
  display: block;
  position: absolute;
  left: -2px;
  top: -1px;
  height: 44px;
  width: 10px;
  background-color: #2d2d2f;
  border-radius: 20px;
  box-shadow: inset -1px -1px 0 -1px rgba(255,255,255,0.6);
}
.phone-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.phone-bottom .bottom-speaker {
  position: absolute;
  display: -webkit-box;
  display: flex;
  left: calc(50% - 120px);
  bottom: 6px;
  -webkit-transform: skewX(-50deg);
          transform: skewX(-50deg);
}
.phone-bottom .bottom-speaker.right {
  left: calc(50% + 45px);
}
.phone-bottom .bottom-speaker div {
  height: 6px;
  width: 6px;
  background-color: #212121;
  box-shadow: inset -4px -3px 2px -2px #000, 1px -1px 0 -1px rgba(255,255,255,0.5);
  margin: 0 3px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}
.phone-bottom .bottom-speaker div:after {
  content: "";
  display: block;
  height: 3px;
  width: 3px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  bottom: -1px;
  left: 3px;
  opacity: 0.4;
}
.phone-bottom .phone-screw {
  position: absolute;
  bottom: 5px;
  left: calc(50% - 38px);
  height: 9px;
  width: 9px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(30%, #1a1a1c), color-stop(60%, #a1a2a7), color-stop(80%, #1a1a1c));
  background-image: linear-gradient(to right, #1a1a1c 30%, #a1a2a7 60%, #1a1a1c 80%);
  border-radius: 50%;
  -webkit-transform: translateX(-50%) skewX(-50deg);
          transform: translateX(-50%) skewX(-50deg);
}
.phone-bottom .phone-screw.right {
  left: calc(50% + 36px);
}
.phone-bottom .phone-screw div {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #252527;
  box-shadow: 2px 2px 0 0 rgba(0,0,0,0.9), inset -2px 1px 1px -1px #000;
  position: relative;
}
.phone-bottom .phone-screw div:after {
  content: "";
  display: block;
  height: 4px;
  width: 4px;
  background-color: #151515;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  position: absolute;
  top: 1px;
  box-shadow: inset -1px -1px 0 0px rgba(255,255,255,0.6);
}
.phone-bottom .phone-charger {
  position: absolute;
  left: 50%;
  bottom: 2px;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(5%, #121214), color-stop(20%, #434446), color-stop(60%, #434446), color-stop(75%, #7a7b80), color-stop(90%, #1b1b1d));
  background-image: linear-gradient(to right, #121214 5%, #434446 20%, #434446 60%, #7a7b80 75%, #1b1b1d 90%);
  -webkit-transform: translateX(-50%) skewX(-50deg);
          transform: translateX(-50%) skewX(-50deg);
  height: 14px;
  width: 48px;
  overflow: hidden;
  border-radius: 15px 16px 24px 20px;
  box-shadow: 1px 1px 1px -1px rgba(255,255,255,0.4), inset -2px -2px 0 -1px #0e0e0e, inset -3px -3px 0 -1px #252527, inset 3px 3px 0 -1px #050505;
}
.phone-bottom .phone-charger:after {
  content: "";
  display: block;
  height: 10px;
  width: 42px;
  background-color: #050505;
  border-radius: 20px 8px 16px 10px;
  box-shadow: 1px 1px 0 -1px rgba(255,255,255,0.4);
}
.phone-bottom .phone-antena {
  position: absolute;
  height: 20px;
  width: 50px;
  bottom: 8px;
  left: 60px;
  border-radius: 40%;
  opacity: 0.7;
  -webkit-transform: rotate(-45deg) skewX(20deg) scaleY(-1);
          transform: rotate(-45deg) skewX(20deg) scaleY(-1);
  background-color: transparent;
  box-shadow: 0px -2px 1px -2px #000;
}
.phone-bottom .phone-antena.right {
  left: auto;
  right: 70px;
  box-shadow: 0px -2px 1px -2px #000, 19px -2px 0 -2px rgba(255,255,255,0.4);
}
.phone-bottom .phone-antena.right:after {
  box-shadow: 0px -2px 1px -2px #000, 19px -2px 0 -2px rgba(255,255,255,0.4);
}
.phone-bottom .phone-antena:after {
  content: "";
  display: block;
  height: 20px;
  width: 50px;
  position: absolute;
  -webkit-transform: rotate(-2deg);
          transform: rotate(-2deg);
  left: -3px;
  top: 3px;
  border-radius: 40%;
  background-color: transparent;
  box-shadow: 0px -2px 1px -2px #000;
}
.phone-reflections {
  position: absolute;
  top: -17px;
  left: 15px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 709px;
  width: 399px;
  border-radius: 48px 40px 52px 45px;
  background-color: transparent;
  z-index: 4;
  overflow: hidden;
}
.phone-reflections .reflection-1 {
  position: absolute;
  height: 200%;
  width: 120%;
  background-color: #fff;
  opacity: 0.1;
  left: -320px;
  top: -200px;
  -webkit-transform: rotate(32deg);
          transform: rotate(32deg);
  z-index: 10;
}
.phone-reflections .reflection-2,
.phone-reflections .reflection-3 {
  position: absolute;
  height: 100%;
  left: -94px;
  width: 100px;
  border-radius: 100px;
  background-color: #000;
  top: calc(50% + 14px);
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 11;
}
.phone-reflections .reflection-3 {
  background: #6d6c71;
  left: -8px;
  height: 60%;
  width: 10px;
  opacity: 0.6;
  top: 70%;
}
.phone-reflections .reflection-4 {
  position: absolute;
  height: 60%;
  top: 0;
  background-color: #67686c;
  width: 16px;
}
.phone-reflections .reflection-4:before {
  content: "";
  display: block;
  position: absolute;
  height: 100px;
  width: 100px;
  background-color: transparent;
  left: 16px;
  top: 4px;
  border-radius: 44px 40px 0 0;
  box-shadow: -20px 0px 0 0 #67686c;
}
.phone-reflections .reflection-4:after {
  content: "";
  display: block;
  height: 80px;
  width: 80px;
  background-color: transparent;
  position: absolute;
  border-radius: 60px 0 0 0;
  left: 0px;
  top: -1px;
  -webkit-transform: rotate(15deg);
          transform: rotate(15deg);
  box-shadow: -25px -3px 2px -8px #000;
}
.phone-reflections .reflection-5 {
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 4px;
  width: 12px;
  background-color: #000;
  border-radius: 50%;
}
.phone-reflections .reflection-6 {
  position: absolute;
  width: 80px;
  height: 20px;
  background-color: #000;
  border-radius: 50%;
  -webkit-transform: rotate(40deg);
          transform: rotate(40deg);
  bottom: -4px;
  left: -14px;
  z-index: 12;
}
.phone-reflections .reflection-6:after {
  content: "";
  display: block;
  position: absolute;
  height: 100px;
  width: 20px;
  background-color: #000;
  right: -17px;
  top: -78px;
  border-radius: 40%;
  -webkit-transform: rotate(50deg);
          transform: rotate(50deg);
}
.phone-reflections .reflection-6:before {
  content: "";
  display: block;
  position: absolute;
  height: 50px;
  width: 50px;
  background-color: transparent;
  top: -51px;
  left: 7px;
  -webkit-transform: rotate(-20deg);
          transform: rotate(-20deg);
  border-radius: 40%;
  box-shadow: 4px 15px 1px -10px #000;
}
.reflection-7 {
  position: absolute;
  bottom: -4px;
  left: 10px;
  height: 20px;
  width: 40px;
  border-radius: 20px 0px 6px 10px;
  -webkit-transform: skewX(60deg) skewY(-10deg) scaleY(-1) scaleX(-1) scale(0.8);
          transform: skewX(60deg) skewY(-10deg) scaleY(-1) scaleX(-1) scale(0.8);
  background-color: #5c5d61;
  z-index: 13;
}
.reflection-7:after {
  content: "";
  display: block;
  height: 20px;
  width: 30px;
  background-color: #5c5d61;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  right: 6px;
  -webkit-transform: rotate(40deg) skewX(-54deg) skewY(47deg) scaleY(1) scaleX(1);
          transform: rotate(40deg) skewX(-54deg) skewY(47deg) scaleY(1) scaleX(1);
}
.reflection-7:before {
  content: "";
  display: block;
  position: absolute;
  height: 18px;
  width: 15px;
  background-color: #5c5d61;
  left: 6px;
  bottom: -6px;
  border-radius: 2px;
  -webkit-transform: rotate(253deg) skewX(10deg);
          transform: rotate(253deg) skewX(10deg);
}
.display-content {
  position: absolute;
  top: 0;
  left: 0;
  height: 690px;
  width: 378px;
  border-radius: 45px 37px 41px 41px;
  overflow: hidden;
  background: linear-gradient(-50deg, #252627 0%, #525358 100%);
}
.display-content iframe {
  overflow: hidden;
}
.notifications-bar .time {
  color: #fff;
  font-size: 11px;
  position: absolute;
  left: 40px;
  top: 10px;
}
.notifications-bar .range {
  position: absolute;
  height: 4px;
  width: 2px;
  background-color: #fff;
  right: 70px;
  top: 14px;
}
.notifications-bar .range:after,
.notifications-bar .range:before {
  content: "";
  display: block;
  position: absolute;
  height: 6px;
  width: 2px;
  background-color: #fff;
  bottom: 0;
  left: 4px;
}
.notifications-bar .range:before {
  height: 8px;
  left: 8px;
}
.notifications-bar .wifi {
  position: absolute;
  right: 40px;
  top: 10px;
}
.notifications-bar .wifi,
.notifications-bar .wifi:before {
  display: inline-block;
  border: 4px double transparent;
  border-top-color: #fff;
  border-radius: 50%;
}
.wifi:before {
  content: '';
  width: 0;
  height: 0;
  position: relative;
  top: -5px;
}
.battery {
  height: 5px;
  width: 12px;
  position: absolute;
  top: 12px;
  right: 20px;
  border-radius: 1px;
  border: 1px solid #fff;
}
.battery:after {
  content: "";
  position: absolute;
  height: 2px;
  width: 1px;
  background-color: #fff;
  right: -3px;
  top: 1px;
}
.content {
  margin-top: 25px;
  width: 100%;
  height: 100%;
  border: 0;
  overflow: hidden;
}

.home-button {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 120px;
  height: 4px;
  background-color: rgba(0,0,0,0.15);
  bottom: 12px;
  border-radius: 4px;
}




.performance {
  color: #000;
  background: #f4f4f4;
}

.performance > div {
  width: 100vw;
  box-sizing: border-box;
  max-width: 980px;
  margin: 0 auto;
  padding: 4em 1em 2em;
  background-color: #eee;
  background: linear-gradient(to bottom, #f4f4f4 0%, #eee 30%)
}

.performance .sourcenote {
  color: #aaa;
  font-size: .85em;
  text-align: center;
  margin: 2em 2.5em -.5em;
}

.performance h3 {
  margin: .5em;
}

.performance p {
  margin: .5em .65em;
}

.savingsChart {
  display: flex;
  margin-top: 3.8em;
  height: 180px;
  justify-content: space-evenly;
  align-items: flex-end;
  position: relative;
}

.savingsBar {
  width: 16%;
  min-width: 70px;
  background-color: #aaa;
  text-align: center;
  border-radius: 0 0 5px 5px;
  position: relative;
  box-sizing: border-box;
  transition: all .2s ease-out;
  box-shadow: 1px 0px 2px rgba(0,0,0,.2);
}

.savingsBar > span {
  position: absolute;
  display: flex;
  top: -1.6em;
  width: 100%;
  left: 0;
  justify-content: center;
}

.savingsBar.gold {
  background: linear-gradient(-60deg, #bca36c, #e4c685);
}

.savingsBar.up {
  border-radius: 5px 5px 0 0;
}

.savingsBar.up > span:before {
  content: "+"
}

.zeroline {
  position: absolute;
  transition: all .2s ease-out;
  left: 0;
  right: 0;
  top: 0;
  background: radial-gradient(farthest-side at center bottom, #f8f8f8ff, #f8f8f800);
  border-bottom: 1px solid #ccc;
}

.savingsControl, .savingsLabels {
  margin: 1em 0 1.5em;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: center;
}

.savingsControl {
  padding: 1em 1em 0;
  background-color: #f8f8f8;
  border-radius: .3em;
  margin: 0 1em;
  box-shadow: 0 5px 15px rgba(0,0,0,.1);
}

.savingsControl > div {
  flex: 1 0 250px;
}

.savingsControl output {
  font-weight: bold;
}


.savingsLabels > label {
  width: 33%;
  margin: 0 -8%;
  font-weight: bold;
}



.savingsControl input[type=range] {
  display: block;
  width: calc(100% - 6em);
  margin: 1.2em auto 2.5em;
  -webkit-appearance: none;
  appearance: none;
}

.savingsControl input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  /* box-shadow: 0px 0px 0px #000000; */
  background: #e8e8e8;
  box-shadow: inset 0 0 2px #666;
  border-radius: 16px;
  border: 0;
}
.savingsControl input[type=range]::-webkit-slider-thumb {
  height: 26px;
  width: 26px;
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin: -7px 0;
  box-shadow: 0 0 2px #333;
}
.savingsControl input[type=range]:focus::-webkit-slider-runnable-track {
  box-shadow: inset 0 0 2px #666, 0 0 0 1px #fff, 0 0 0 2px #bca36c;
}
.savingsControl input[type=range]::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  /* box-shadow: 0px 0px 0px #000000; */
  background: #e8e8e8;
  box-shadow: inset 0 0 2px #666;
  border-radius: 16px;
  border: 0;
}
.savingsControl input[type=range]::-moz-range-thumb {
  height: 26px;
  width: 26px;
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
  -moz-appearance: none;
  appearance: none;
  margin: -7px 0;
  box-shadow: 0 0 2px #333;
}
.savingsControl input[type=range]::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  /* box-shadow: 0px 0px 0px #000000; */
  background: #e8e8e8;
  box-shadow: inset 0 0 2px #666;
  border-radius: 16px;
  border: 0;
}
/* input[type=range]::-ms-fill-lower {
  background: #e8e8e8;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
}
input[type=range]::-ms-fill-upper {
  background: #e8e8e8;
  border: 0px solid #000101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
} */
.savingsControl input[type=range]::-ms-thumb {
  height: 26px;
  width: 26px;
  border-radius: 13px;
  background-color: #fff;
  cursor: pointer;
  -ms-appearance: none;
  appearance: none;
  margin: -7px 0;
  box-shadow: 0 0 2px #333;
}
/* input[type=range]:focus::-ms-fill-lower {
  background: #ac51b5;
}
input[type=range]:focus::-ms-fill-upper {
  background: #ac51b5;
} */

.support h3:before {
  content: "";
  display: block;
  margin: 25px auto;
  border-top: 2px solid #e3e3e3;
  width: 100%;
}

.support h3:after {
  display: none;
}

.support h3 {
  margin-bottom: 0.8em;
  width: 100%;
}

.support .fields {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support .row {
  display: flex;
  justify-content: center;
  column-gap: 2em;
  flex-wrap: wrap;
}

@media (min-width: 630px) {
  .support textarea {
    width: calc(536px + 2em);
  }
  
}

.support button {
  margin-top: 1.5em;
}